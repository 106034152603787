<template>
  <v-container fluid class='report-card align-self-sm-stretch'>
    <v-row class='d-flex card-title'>
      <v-row class='pa-0 ma-0 d-flex justify-space-between'>
        <span class='report-title'> {{ $t('QUALIDADE_IMPRESSAO_REPORT_TITLE') }} </span>
        <v-icon
          class='mr-4'
          small
          @click='fetchWidgetContent'
        >
          mdi-reload
        </v-icon>
      </v-row>
    </v-row>
    <v-row class='active-filters-text'>
      <span>{{ $t('FROM') }}: </span>
      <span class='ml-1'>{{ startDate ? `${startDate.substring(8, 10)}/${startDate.substring(5, 7)}/${startDate.substring(0, 4)}` : '--' }}</span>
      <span class='ml-4'>{{ $t('UNTIL') }}: </span>
      <span class='ml-1'>{{ endDate ? `${endDate.substring(8, 10)}/${endDate.substring(5, 7)}/${endDate.substring(0, 4)}` : '--' }}</span>
    </v-row>
    <v-divider class='my-6' />
    <v-row class='d-flex justify-center mt-2'>
      <v-card-text v-if='hasData'>
        <v-row align='center' justify='center'>
          <apexchart
            :key='chartKey'
            ref='chart'
            type='donut'
            width='500'
            :options='chartPieOptions'
            :series='chartPieData'
          />
        </v-row>
      </v-card-text>
      <span v-else>
        {{ $t('NO_DATA') }}
      </span>
    </v-row>
    <v-row
      class='report-footer'
    >
      <v-icon
        @click='openFilters'
      >
        mdi-filter-outline
      </v-icon>
    </v-row>
    <v-dialog
      v-model='filterDialog'
      fullscreen
      hide-overlay
      transition='dialog-bottom-transition'
    >
      <v-card>
        <Filters @onFilter='saveFilter' @applyFilters='fetchWidgetContent' @close='filterDialog = false' />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios';

  export default {
    components: {
      Filters: () => import('./Filters.vue'),
    },
    data () {
      return {
        reportName: 'Qualidade das impressões digitais',
        value: null,
        startDate: null,
        chartKey: 1,
        hasData: true,
        endDate: null,
        filterDialog: false,
        nfiqCode: {
          one: this.$t('NFIQ_VERY_GOOD'),
          two: this.$t('NFIQ_GOOD'),
          three: this.$t('NFIQ_NORMAL'),
          four: this.$t('NFIQ_BAD'),
          five: this.$t('NFIQ_VERY_BAD'),
        },
        chartPieOptions: {
          chart: {
            width: 500,
            type: 'donut',
          },
          labels: [],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: 'bottom',
              },
            },
          }],
        },
        chartPieData: [],
      };
    },
    mounted: function () {
      this.fetchWidgetContent();
    },
    methods: {
      async fetchWidgetContent () {
        if (this.startDate && !this.endDate) {
          const today = new Date().toISOString().split('T')[0];
          this.endDate = `${today} 23:59:59}`;
        }

        this.filterDialog = false;
        const { data } = await axios({
          url: '/report-data',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
          params: {
            name: this.reportName,
            filter: {
              date_filter: this.startDate ? `b.created_at >= '${this.startDate}' and b.created_at <= '${this.endDate}'` : null,
            },
          },
        });
        this.value = data && data.data && data.data[0] ? data.data[0] : null;
        const nfiq = Object.keys(this.nfiqCode);
        this.chartPieOptions.labels = [this.nfiqCode[nfiq[0]], this.nfiqCode[nfiq[1]], this.nfiqCode[nfiq[2]], this.nfiqCode[nfiq[3]], this.nfiqCode[nfiq[4]]];
        this.chartPieData = [parseInt(this.value[nfiq[0]], 10), parseInt(this.value[nfiq[1]], 10), parseInt(this.value[nfiq[2]], 10), parseInt(this.value[nfiq[3]], 10), parseInt(this.value[nfiq[4]], 10)];
        if (this.chartPieData.reduce((a, b) => a + b, 0) === 0) {
          this.hasData = false;
        } else {
          this.hasData = true;
        }
        this.chartKey++;
      },
      removeWidget (id) {
        document.removeItem(id);
      },
      saveFilter (filter) {
        this.startDate = filter.startDate;
        this.endDate = filter.endDate;
      },
      openFilters () {
        this.filterDialog = true;
      },
    },
  };
</script>
<style>
  .report-footer {
    position: absolute;
    bottom: 10vh;
  }
  html {
   overflow: hidden;
  }
  .report-card {
    background-color: #FFFFFF;
    height: 100vh;
    overflow-y: hidden;
  }
  .content-value {
    margin-top: 20vh !important;
  }
  .date-picker {
    z-index: 999;
    animation: createBox 0.5s;
  }
  @keyframes createBox {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  .active-filters-text {
    font-size: 13px;
  }
</style>
